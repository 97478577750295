<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-6">
              <base-input
                label="Título da Notícia"
                v-model="form.title"
              ></base-input>
            </div>

            <div class="col-6">
              <label for="">Categoria</label>
              <select v-model="form.category_id" class="form-control">
                <option
                  v-for="category in categories"
                  :key="category.title"
                  :value="category.id"
                  >{{ category.title }}</option
                >
              </select>
            </div>

            <div class="col-12">
              <vue-editor v-model="form.content"></vue-editor>
            </div>

            <div class="col-12 mt-4">
              <label>Imagem </label>
              <div class="upload-container">
                <div
                  class="upload-preview"
                  :style="'background: url(' + form.image + ')'"
                  v-if="form.image !== '' && form.image !== null"
                >
                  <div class="remove-file" @click="form.image = ''">
                    <p><i class="fa fa-times"></i></p>
                    <p>Remover</p>
                  </div>
                </div>
                <div class="add-file">
                  <label
                    for="image"
                    v-if="!(form.image !== '' && form.image !== null)"
                  >
                    Carregar <span class="tim-icons icon-upload"></span>
                  </label>
                  <label
                    for="image"
                    v-if="form.image !== '' && form.image !== null"
                  >
                    Alterar <span class="tim-icons icon-pencil"></span>
                  </label>
                  <input
                    @change.prevent="addImage"
                    type="file"
                    id="image"
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </div>
              </div>
            </div>

            <div class="col-6">
              <label for="">Status</label>
              <base-radio v-model="form.status" inline name="1"
                >Ativo</base-radio
              >
              <base-radio v-model="form.status" inline name="0"
                >Inativo</base-radio
              >
            </div>
          </div>

          <!-- botoes -->
          <div class="col-12">
            <base-button
              class="mt-3"
              @click.prevent.stop="goBack()"
              type="danger"
              >Cancelar</base-button
            >
            <base-button class="mt-3" native-type="submit" type="success"
              >Salvar</base-button
            >
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { BaseRadio } from '@/components/index'

import swal from 'sweetalert2'
import { VueEditor } from 'vue2-editor'

export default {
  data: () => ({
    form: {
      status: 1,
      image: ''
    },
    categories: []
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`news/${id}`)).data
      }
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`news/${id}`, this.form)).status
          }
          if (!id) status = (await this.$http.post('news', this.form)).status
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          swal({
            title: 'Salvo!',
            text: `"${this.form.title}" salvo com sucesso.`,
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.goBack()
        } else {
          swal({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Ok',
            showConfirmButton: true
          })
        }
      } else {
        swal({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          type: 'error',
          confirmButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          showConfirmButton: true
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    addImage (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      var reader = new FileReader()

      reader.onload = e => {
        vue.form.image = e.target.result
      }
      reader.readAsDataURL(file)
    },

    validate () {
      const form = this.form
      if (!form.title || !form.title.trim()) return false
      if (!form.image || form.image === '') return false
      if (!form.content || form.content === '') return false
      if (!form.category_id || form.category_id === '') return false
      return true
    },
    async getCategories () {
      this.categories = (await this.$http.get('categories/type/Notícias')).data
    }
  },
  mounted () {
    this.getData()
    this.getCategories()
  },
  components: {
    BaseRadio,
    VueEditor
  }
}
</script>
<style></style>
